export const cEstadoVenta = {
    noDisponible: -1,
    creado: 0,
    habilitado: 1,
    deshabilitado: 2,
    bloqueado: 3,
    noVerificado: 4,
    restableciendoPassword: 5,
    emitido: 6,
    anulado: 7,
    certificado: 8,
    enProceso: 9,
    fallida: 10,
}
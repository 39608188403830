import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { GeneralService, UsuarioService } from 'src/app/services/services';
import { AuthService } from 'src/app/services/services';
import { apiRoutes } from './../../../../app/constants/api-routes.constants';

import { statusCodes } from './../../../../app/constants/constants'
// import { StartComponent} from 'src/app/views/app/start/start.component'

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopnavComponent implements OnInit, OnDestroy {

  @ViewChild('studentModal')
  private studentModalTemplate: TemplateRef<any>;

  modalRefRol: BsModalRef;
  @ViewChild('RolModal', { static: true })
  private RolModalTemplate: TemplateRef<any>;

  modalRefPassword: BsModalRef;
  @ViewChild('passwordModal', { static: true })
  private passwordModalTemplate: TemplateRef<any>;

  lstStudent: any[] = [];
  objStudent: any = null;
  modalRef: BsModalRef;

  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  rolId: any;
  authRol: any;
  intRolId: any = -1;
  fotografia = "/assets/img/profiles/responsible.png";
  objCambio: any = {};


  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private modalService: BsModalService,
    private GeneralService: GeneralService,
    private UsuarioService: UsuarioService
    // ,
    // private starComp: StartComponent
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    this.displayName = "";
    let objAuthData = this.authService.getAuthData();
    //this.lstStudent = objAuthData.hijos;
    if (objAuthData.fotografia) {
      this.fotografia = objAuthData.fotografia;

    }

    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.authService.logout();
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }

  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  openModal(): void {
    this.lstStudent.forEach(x => x.selected = false);
    let objStudentData = this.authService.getStudentData();
    let objStudent = this.lstStudent.find(x => x.estudianteId == objStudentData.estudianteId);
    objStudent.selected = true;
    this.objStudent = objStudent;
    this.modalRef = this.modalService.show(this.studentModalTemplate, { class: 'modal-lg' });
  }

  selectStudent(student: any) {
    this.lstStudent.forEach(x => x.selected = false);
    student.selected = true;
    this.objStudent = student;
  }

  saveStudent() {
    this.authService.setStudentData(this.objStudent);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  cambiarRolModal() {

    this.intRolId = this.authService.getRolId();
    this.authRol = this.authService.getArrRol();

    this.modalRefRol = this.modalService.show(this.RolModalTemplate, { backdrop: true, ignoreBackdropClick: true, class: 'modal-content modal-lg' });

    // this.starComp.cambiarRolModal();
  }

  submitCambioRol() {
    this.authService.returnHome();
    this.getData(this.intRolId);
  }

  updatePasswordModal(){
    this.objCambio = {};
    this.modalRefPassword = this.modalService.show(this.passwordModalTemplate, { backdrop: true, ignoreBackdropClick: true, class: 'modal-content modal-lg' });
  }

  getData(rolId: any) {
    this.GeneralService.getData(apiRoutes.ROUTE_CAMBIO_ROL + '?rol=' + rolId
    ).then((data: any) => {
      if (data) {
        this.authService.setCambioRol(data);
        this.hideModal();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  hideModal() {
    this.modalRefRol.hide();
  }

  submitUpdatePassword() {
    if (!this.objCambio) return;

    this.UsuarioService.updatePassword(this.objCambio).then((response: any) => {
      if(response.success){
        this.hideModalPassword();
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  hideModalPassword() {
    this.modalRefPassword.hide();
    this.objCambio = {};
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';

@Injectable({ providedIn: 'root' })
export class PerfilEstudianteService
{
  constructor(private http: HttpClient, private notificationService: NotificationService,private loaderService: NgxUiLoaderService) {
  }
  
  getDataById(Id: number) 
  {
    this.loaderService.start();
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_PERFIL_ESTUDIANTE + '/' + '?estudiante=' + Id).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        this.loaderService.stop();
        if(success) resolve(response.data);
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        this.loaderService.stop();
        reject(error);
      });
    });
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({ providedIn: 'root' })
export class GeneralService {
    constructor(private http: HttpClient, private notificationService: NotificationService,
        private loaderService: NgxUiLoaderService) {
    }

    getData(Ruta: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(Ruta).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    getParamsData(Ruta: any, Params: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(Ruta, { params: Params }).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    getDataById(ruta: any, id: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(ruta + "/" + id).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    CreateOrUpdateData(ruta: any, body: any, method: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http[method]<any>(ruta, body).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    CreateOrUpdateDataResponse(ruta: any, body: any, method: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http[method]<any>(ruta, body).subscribe(
                (response: any) => {                    
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve({ success: success, data: response.data ? response.data : null, estado:response.estado ? response.estado: null });
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    patchData(ruta: any, body: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http['patch']<any>(ruta, body).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    putData(ruta: any, body: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.put(ruta, body).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    deleteData(ruta: any, id: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.delete(ruta + '/' + id).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }
    deleteWithoutId(ruta: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.delete(ruta).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    convertDateToString(fecha: Date) {
        return fecha.toISOString().split('T')[0];
    }

}
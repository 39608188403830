import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../../constants/constants';
import { NotificationService } from '../notification.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({ providedIn: 'root' })
export class VentaService {

    constructor(private http: HttpClient, private notificationService: NotificationService,
                private loaderService: NgxUiLoaderService) {
    }

    getData() {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_PRODUCTO).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    getDataById(id: any) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.get(apiRoutes.ROUTE_PRODUCTO + "/" + id).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, false);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    resolve(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    sendData(Venta: any) {
        this.loaderService.start();

        return new Promise((resolve, reject) => {
            this.http['post']<any>(apiRoutes.ROUTE_VENTA, Venta).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    if (success) resolve(response.data);
                    reject(null);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

    deleteData(IdBodega: number) {
        this.loaderService.start();
        return new Promise((resolve, reject) => {
            this.http.delete(apiRoutes.ROUTE_PRODUCTO + '/' + IdBodega).subscribe(
                (response: any) => {
                    let success = this.notificationService.verifyStatus(response, true);
                    this.loaderService.stop();
                    resolve(success);
                },
                (error: any) => {
                    this.notificationService.error(error);
                    this.loaderService.stop();
                    reject(error);
                });
        });
    }

}
import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
  descripcion?:string;
}

const data: IMenuItem[] = [
  {
    icon: 'fas fa-cogs',
    label: 'Configuración',
    to: `${adminRoot}/configuracion`,
    subs: [
      {
        icon: 'fas fa-cogs',
        label: 'Usuarios',
        to: `${adminRoot}/configuracion/usuario`,
        subs: [
          {
            icon: 'fas fa-cogs',
            label: 'Formulario',
            to: `${adminRoot}/configuracion/usuario/form`
          }
        ]
      }
    ]
  },
  {
    icon: 'fas fa-cogs',
    label: 'Gestión',
    to: `${adminRoot}/operacion`,
    subs: [
      {
        icon: 'fas fa-cogs',
        label: 'Ciclo',
        to: `${adminRoot}/operacion/ciclo-operacion`,
        subs: [
          {
            icon: 'fas fa-cogs',
            label: 'Inscripción',
            to: `${adminRoot}/operacion/ciclo-operacion/inscripcion`
          }
        ]
      }
    ]
  }
];

export default data;

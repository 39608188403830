import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { notificationTypes, statusCodes } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class NotificationService
{
    constructor(private toastrService: ToastrService, private router: Router) { }

    openNotify(message: string, title: string, type: number): void {
        switch (type) {
            case notificationTypes.SUCCESS: this.toastrService.success(message, title); break;
            case notificationTypes.INFO: this.toastrService.info(message, title); break;
            case notificationTypes.WARNING: this.toastrService.warning(message, title); break;
            case notificationTypes.ERROR: this.toastrService.error(message, title); break;
            case notificationTypes.DEFAULT: this.toastrService.success(message, title); break;
        }
    }

    success(message: string) {
        this.openNotify(message, null, notificationTypes.SUCCESS);
    }

    info(message: string) {
        this.openNotify(message, null, notificationTypes.INFO);
    }

    warning(message: string) {
        this.openNotify(message, null, notificationTypes.WARNING);
    }

    error(error: any) {
        console.log(error);
        this.openNotify(statusCodes.errorConexionServidor.message, null, notificationTypes.ERROR);
    }

    verifyStatus(operation: any, blnNotifyOk: boolean): boolean {
        let success = false;
        if (operation) {
            switch (operation.estado) {
                case statusCodes.ok.code:
                    success = true;
                    if (blnNotifyOk) this.openNotify(statusCodes.ok.message, null, notificationTypes.SUCCESS);
                    break;
                case statusCodes.errorConexionServidor.code:
                    this.openNotify(statusCodes.errorConexionServidor.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioPasswordIncorrecto.code:
                    this.openNotify(statusCodes.usuarioPasswordIncorrecto.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.noPermiso.code:
                    this.openNotify(statusCodes.noPermiso.message, null, notificationTypes.ERROR);
                    this.router.navigate(['/unauthorized']);
                    break;
                case statusCodes.datoIncompletoFormulario.code:
                    this.openNotify(statusCodes.datoIncompletoFormulario.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioProblemaRol.code:
                    this.openNotify(statusCodes.usuarioProblemaRol.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.datoYaRegistrado.code:
                    this.openNotify(statusCodes.datoYaRegistrado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.tipoServicioNoAdecuado.code:
                    this.openNotify(statusCodes.tipoServicioNoAdecuado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.datosFormularioNoAdecuados.code:
                    this.openNotify(statusCodes.datosFormularioNoAdecuados.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.capacidadIncorrecta.code:
                    this.openNotify(statusCodes.capacidadIncorrecta.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.errorFechaInicioMayorFin.code:
                    this.openNotify(statusCodes.errorFechaInicioMayorFin.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.datosNoExistentes.code:
                    this.openNotify(statusCodes.datosNoExistentes.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.estudianteInscrito.code:
                    this.openNotify(statusCodes.estudianteInscrito.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioBloqueado.code:
                    this.openNotify(statusCodes.usuarioBloqueado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioDeshabilitado.code:
                    this.openNotify(statusCodes.usuarioDeshabilitado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.rolNoPerteneciente.code:
                    this.openNotify(statusCodes.rolNoPerteneciente.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.materiaNoPerteneciente.code:
                    this.openNotify(statusCodes.materiaNoPerteneciente.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioExistente.code:
                    this.openNotify(statusCodes.usuarioExistente.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioYaVerificado.code:
                    this.openNotify(statusCodes.usuarioYaVerificado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.usuarioNoVerificado.code:
                    this.openNotify(statusCodes.usuarioNoVerificado.message, null, notificationTypes.ERROR);
                    break;
                case statusCodes.tokenExpirado.code:
                    this.openNotify(statusCodes.tokenExpirado.message, null, notificationTypes.ERROR);
                    localStorage.removeItem('authData');
                    localStorage.removeItem('studentData');
                    this.router.navigate(['user/login']);
                    break;
                default:
                    this.openNotify(operation.mensaje, null, notificationTypes.ERROR);
                    break;
            }
        }
        return success;
    }
}

export const cTipoVenta = {
    CREDITO: 1,
    CONTADO: 2
};
export const cTipoBusquedaFacturar = {
    NIT: 1,
    ESTUDIANTE: 2,
    SIN_ESTUDIANTE: 3,
    TODOS: 4
};
export const lstBusquedaFacturar = [
    {
        id: 1,
        name: 'NIT'
    },
    {
        id: 2,
        name: 'Por estudiante'
    },
    {
        id: 3,
        name: 'Sin estudiante'
    }
];
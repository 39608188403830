<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
    <ol class="breadcrumb pt-0">
        <ng-container *ngFor="let sub of pathArr; let i=index" >
            <li class="breadcrumb-item" [hidden]="i >= (pathArr.length-1)" *ngIf="getLabel(getUrl(sub)).length > 0">
                <a [routerLink]="[getUrl(sub)]">{{getLabel(getUrl(sub)) | translate}}</a>
            </li>     
        </ng-container>        
        <li class="breadcrumb-item active" aria-current="page">{{ title || (getLabel('/'+path) | translate) }}</li>
    </ol>
</nav>

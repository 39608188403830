export const statusCodes = {
    ok: {
        code: 0,
        message: "Operación se ha realizado de forma exitosa."
    },
    errorConexionServidor: {
        code: 1,
        message: "Ocurrio un error con la conexión del servidor, intente más tarde."
    },
    usuarioPasswordIncorrecto: {
        code: 2,
        message: "Usuario y/o Password Incorrectos."
    },
    noPermiso: {
        code: 3,
        message: "No tiene permisos para realizar esta acción."
    },
    datoIncompletoFormulario: {
        code: 4,
        message: "Los datos del formulario están incompletos."
    },
    usuarioProblemaRol: {
        code: 5,
        message: "Usuario con problemas de rol."
    },
    datoYaRegistrado: {
        code: 6,
        message: "Los datos ya se encuentran registrados."
    },
    tipoServicioNoAdecuado: {
        code: 7,
        message: "Tipo de servicio no adecuado."
    },
    datosFormularioNoAdecuados: {
        code: 8,
        message: "Los datos del formulario no son los adecuados."
    },
    capacidadIncorrecta: {
        code: 9,
        message: "Error, Capacidad Incorrecta."
    },
    errorFechaInicioMayorFin: {
        code: 10,
        message: "Error, Fecha Inicio mayor a la Fecha Fin."
    },
    datosNoExistentes: {
        code: 11,
        message: "Error, Datos no existentes."
    },
    estudianteInscrito: {
        code: 12,
        message: "Usuario inscrito."
    },
    usuarioBloqueado: {
        code: 13,
        message: "Usuario Bloqueado."
    },
    usuarioDeshabilitado: {
        code: 14,
        message: "Este usuario esta deshabilitado."
    },
    rolNoPerteneciente: {
        code: 15,
        message: "Rol no perteneciente."
    },
    materiaNoPerteneciente: {
        code: 16,
        message: "Materia no perteneciente."
    },
    usuarioExistente: {
        code: 17,
        message: "Este usuario o correo electrónico ya esta registrado."
    },
    usuarioYaVerificado: {
        code: 18,
        message: "Correo electrónico ya verificado."
    },
    usuarioNoVerificado: {
        code: 19,
        message: "Correo electrónico no verificado."
    },
    tokenExpirado: {
        code: 24,
        message: "Token expirado o invalido."
    }
}














export const carousel = {
    type: 'carousel',
    perView: 1,
    hideNav: false, 
    breakpoints: {
      '320': { perView: 1 }, 
      '576': { perView: 2 },
      '1800': { perView: 3 },
      '1900': { perView: 4 } 
    } 
};
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiRoutes } from '../constants/constants';
import { NotificationService } from '../services/notification.service';
import { ILoginData, IAuthData } from '../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthService
{
  private isAuthenticated = false;
  private authData: IAuthData = this.createObj();
  private token: string;
  private authStatusListener = new Subject<boolean>();
  private arrMenu: any;
  private rolId: any;
  private arrRol: any;

  constructor(private http: HttpClient, private notificationService: NotificationService, private router: Router) {
  }

  getAuthData()
  {
    this.authData = JSON.parse(localStorage.getItem('authData'));
    return this.authData;
  }

  getStudentData()
  {
    return JSON.parse(localStorage.getItem('studentData'));
  }

  setStudentData(student: any)
  {
    localStorage.setItem('studentData', JSON.stringify(student));
  }

  createObj()
  {
    return { user: null, token: null, nombre: null, fotografia: null, estados: null, usuarioEstadoId: null,puntoVenta:null,logo : null };
  }

  getToken()
  {
    let authData = this.getAuthData();
    this.token = authData ? authData.token : null;
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getMenu(){
    return this.arrMenu;
  }

  getAuthenticated()
  {
    return this.getToken() != null;
  }

  login(loginForm: any)
  {
    const loginData: ILoginData = loginForm;
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_LOGIN, loginData).subscribe(
      (response: any) => {
        console.log("response", response);
        let success = this.notificationService.verifyStatus(response, false);
        if(success)
        {
          this.authData = this.createObj();
          this.authData = response.data;
          this.authData.user = loginData.user;
          console.log( this.authData);
          if (response.estado == 0 && response.data.usuarioEstadoId == 0 ) {
            this.notificationService.success('Ingresa una nueva contraseña');
            this.loginPassword(response);
            //this.authService.loginPassword(response);           
          }else if (response.estado == 0 && response.data.usuarioEstadoId == 1 ){
            this.notificationService.success(`Bienvenido, ${this.authData.nombre}`);
            this.loginSuccess();
          }else if(response.estado === 2){
              this.notificationService.error('Credenciales incorrectas');
          }else if(response.estado === 13){
              this.notificationService.error('Usuario bloqueado');
          }else {
              this.notificationService.error('Hubo un problema al actualizar');
          }
          
          resolve(response);
        }
        resolve(null);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  existEmail(email: string)
  {
    return new Promise((resolve, reject) => {
      this.http.get(apiRoutes.ROUTE_EXISTEMAIL + `?email=${email}`).subscribe(
      (response: any) => {
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  register(objRegister: any)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_REGISTER, objRegister).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        resolve(success);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  forgotPassword(email: string)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_FORGOTPASSWORD, { email: email }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        if(success) this.notificationService.success(`Se enviado un correo electrónico para restablecer la contraseña, con el cual sera redirigido a la página restablecer contraseña`);
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  verifyChangePassword(token: string)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_VERIFYTOKEN_FP, { token: token }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, false);
        if(!success) {
          this.notificationService.error(`Este solicitud ya ha expirado!`);
          setTimeout(() => {
            this.router.navigate(['error']);
          }, 6000);
        }
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  resetPassword(token: string, password: string)
  {
    return new Promise((resolve, reject) => {
      this.http.post(apiRoutes.ROUTE_RESET_PASSWORD, { token: token, password: password }).subscribe(
      (response: any) => {
        let success = this.notificationService.verifyStatus(response, true);
        if(success) {
          setTimeout(() => {
            this.router.navigate(['user/login']);
          }, 2000);
        }
        resolve(response);
      },
      (error: any) => {
        this.notificationService.error(error);
        reject();
      });
    });
  }

  loginPassword(response: any) {
    const token = response.data.token;
    this.token = token;
    if (token) {
      this.router.navigate(['user/reset'], { queryParams: { token: token } });
    }
  }

  loginSuccess()
  {
    if (this.authData.token)
    {
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
      // localStorage.setItem('puntoVenta',JSON.stringify(this.authData.puntoVenta));
      localStorage.setItem('home','/app'); //this.authData.home
      localStorage.setItem('authData', JSON.stringify(this.authData));
      this.router.navigate(['/app']);
    }
  }

  returnHome(){
    this.router.navigate(['/app/start']);
  }

  ValidarSession() {
    return this.http.get<{ token: string; }>(
      'http://localhost:3000/login'
    );
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    this.token = authInformation.token;
    this.isAuthenticated = true;
    this.authStatusListener.next(true);
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    localStorage.removeItem('authData');
    localStorage.removeItem('studentData');
    this.router.navigate(['user/login']);
  }

  private saveAuthData(token: string)
  {
    /*
    localStorage.setItem('token', token);
    localStorage.setItem('user', this.user);
    localStorage.setItem('fotografia', this.nameUser);
    localStorage.setItem('fotografia', this.fotografia);
    */
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('nameUser');
  }

  getMenuData() {
    let authData = this.getAuthData();
    this.arrMenu = this.createMenu(authData.menu);
    return this.arrMenu;
  }

  getLogo(){
    let authData = this.getAuthData();
    console.log(authData.logo);
    return authData.logo;

  }

  createMenu(lstMenu: any[])
  {
    let lstResult = [];
    for(let menu of lstMenu)
    {
      let objMenu = { icon: menu.icon, label: menu.nombre, to: menu.ruta };
      if(menu.hijos.length > 0) objMenu["subs"] = this.createMenu(menu.hijos);
      lstResult.push(objMenu);
    }
    return lstResult;
  }

  private clearMenuData() {
    localStorage.removeItem('menu');
  }

  private getObjToken()
  {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    return {
      token: token
    };
  }

  getRolId(){
    let authDataString = localStorage.getItem('authData');
    let authObj = JSON.parse(authDataString);
    this.rolId = authObj.rolId;
    return this.rolId;
  }

  getArrRol(){
    let authDataString = localStorage.getItem('authData');
    let authObj = JSON.parse(authDataString);
    this.arrRol = authObj.roles;
    return this.arrRol;
  }

  setCambioRol(response, ){

    this.authData = this.createObj();
    this.authData = response;
    this.authData.user = 'usuario';
    this.notificationService.success(`Bienvenido, ${this.authData.nombre}`);
    this.loginSuccess();
    window.location.reload();
  }
}

import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({ providedIn: 'root' })
export class PDFService {
    constructor() {
    }

    generatePdf(objDataVenta: any) {
        const documentDefinition = {
            pageSize: {
                width: 225,
                height: 'auto'
            },
            pageMargins: [10, 0, 10, 0],
            styles: {
                Header: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center'
                },
                BodyCenter: {
                    fontSize: 9,
                    alignment: 'center'
                },
                BodyLeft: {
                    fontSize: 9,
                    alignment: 'left'
                },
                TableLeft: {
                    fontSize: 8,
                    alignment: 'left'
                },
                centertme:
                {
                    alignment: 'center'
                }

            },
            pageOrientation: 'portrait',
            content: [
                { text: '\n', style: 'BodyCenter' },

                { text: 'Recibo Interno', style: 'Header' },
                this.getTituloFactura("Datos del Emisor"),
                { text: objDataVenta.venta.cuentaCorrienteColegio.nombre, style: 'Header' },
                { text: objDataVenta.venta.cuentaCorrienteColegio.direccion, style: 'BodyCenter' },
                { text: 'Tel:' + objDataVenta.venta.cuentaCorrienteColegio.telefono1 +
                (objDataVenta.venta.cuentaCorrienteColegio.telefono2 ? ' / ' + objDataVenta.venta.cuentaCorrienteColegio.telefono2 : ''), style: 'BodyCenter' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Comprador"),
                { text: 'Fecha:' + objDataVenta.venta.fechaRegistro, style: 'BodyLeft' },
                { text: 'Nit: ' + objDataVenta.venta.nit, style: 'BodyLeft' },
                { text: 'Nombre: ' + objDataVenta.venta.razonSocial, style: 'BodyLeft' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Datos del Recibo"),
                { text: 'Número: ' + objDataVenta.venta.correlativoInterno, style: 'BodyLeft' },
                // { text: 'Autorización: D5054566-3B07-4960-A3A0-F65C39340017', style: 'BodyLeft' },
                // { text: 'Fecha Certificación: 2021-05-22T09:25:15-06', style: 'BodyLeft' },
                // { text: 'Sujeto a pagos trimestrales ISR', style: 'BodyLeft' },
                { text: '\n', style: 'BodyCenter' },
                this.getTituloFactura("Detalle del Recibo"),
                {
                    layout: this.getLayoutTabla().layout,
                    table: {
                        headerRows: 1,
                        widths: [35, 70, 35, 35],
                        body: this.getDetallefactura(objDataVenta)
                    }
                },
                { text: '\n\n', style: 'BodyCenter' },
                // this.getTituloFactura("Datos del Sistema"),
                // { text: 'Plataforma: SimplyGT', style: 'BodyLeft' },
                // { text: 'Certificador: Inforum Consulting', style: 'BodyLeft' },
                // { text: 'NIT: 43430775', style: 'BodyLeft' },
                { text: '\n\n\n', style: 'BodyCenter' }
            ]
        };
        pdfMake.createPdf(documentDefinition).print();
    }

    getLayoutTabla() {
        return {
            layout: {
                // code from lightHorizontalLines:
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 3;
                },
                paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 3;
                },
                // code for zebra style:
                fillColor: function (i, node) {
                    // return (i % 2 === 0) ? '#CCCCCC' : null;
                }
            }
        }
    }

    getTituloFactura(titulo: String) {
        return {
            layout: this.getLayoutTabla().layout,
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{ text: titulo, style: 'BodyCenter' }],
                    [{ text: '', style: 'BodyCenter' }],
                ]
            }
        };
    }

    getDetallefactura(DataVenta: any) {
        let BodyTable = [];
        BodyTable.push([
            { text: 'Cantidad', style: 'TableLeft' },
            { text: 'Descripción', style: 'TableLeft' },
            { text: 'Precio', style: 'TableLeft' },
            { text: 'SubTotal', style: 'TableLeft' }
        ]);
        for (const Detalle of DataVenta.venta.detalleVenta) {
            BodyTable.push([
                { text: Detalle.cantidad, style: 'TableLeft' },
                { text: Detalle.servicio.nombre, style: 'TableLeft' },
                { text: Detalle.precioVenta, style: 'TableLeft' },
                { text: Detalle.subtotal, style: 'TableLeft' }
            ]);
        }
        BodyTable.push(['', '', { text: 'Total', style: 'TableLeft' }, { text: DataVenta.venta.total, style: 'TableLeft' }]);
        return BodyTable;
    }

    getBase64(event) {
        let me = this;
        let file = event;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //me.modelvalue = reader.result;
            console.log(reader.result);
            return reader.result;
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

}
